.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  -o-transition: all .25s ease-in;
  opacity: .4;
  filter: alpha(opacity= 40);
  z-index: 2;
  cursor: pointer;
  background: none;
  border: 0;
  font-size: 32px;
  transition: all .25s ease-in;
  position: absolute;
  top: 20px;
}

.carousel .control-arrow:focus, .carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity= 100);
}

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  content: "";
  border-top: 8px solid #0000;
  border-bottom: 8px solid #0000;
  margin: 0 5px;
  display: inline-block;
}

.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity= 0);
  cursor: inherit;
  display: none;
}

.carousel .control-prev.control-arrow {
  left: 0;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}

.carousel .control-next.control-arrow {
  right: 0;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}

.carousel-root {
  outline: none;
}

.carousel {
  width: 100%;
  position: relative;
}

.carousel * {
  box-sizing: border-box;
}

.carousel img {
  pointer-events: none;
  width: 100%;
  display: inline-block;
}

.carousel .carousel {
  position: relative;
}

.carousel .control-arrow {
  background: none;
  border: 0;
  outline: 0;
  margin-top: -13px;
  font-size: 18px;
  top: 50%;
}

.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
}

.carousel .thumbs {
  -o-transition: all .15s ease-in;
  white-space: nowrap;
  list-style: none;
  transition: all .15s ease-in;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.carousel .thumb {
  -o-transition: border .15s ease-in;
  white-space: nowrap;
  border: 3px solid #fff;
  margin-right: 6px;
  padding: 2px;
  transition: border .15s ease-in;
  display: inline-block;
  overflow: hidden;
}

.carousel .thumb:focus {
  border: 3px solid #ccc;
  outline: none;
}

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 3px solid #333;
}

.carousel .thumb img {
  vertical-align: top;
}

.carousel.carousel-slider {
  margin: 0;
  position: relative;
  overflow: hidden;
}

.carousel.carousel-slider .control-arrow {
  color: #fff;
  margin-top: 0;
  padding: 5px;
  font-size: 26px;
  top: 0;
  bottom: 0;
}

.carousel.carousel-slider .control-arrow:hover {
  background: #0003;
}

.carousel .slider-wrapper {
  -o-transition: height .15s ease-in;
  width: 100%;
  margin: auto;
  transition: height .15s ease-in;
  overflow: hidden;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-flow: column;
}

.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}

.carousel .slider-wrapper.axis-vertical .slider {
  flex-direction: column;
}

.carousel .slider {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.carousel .slider.animated {
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out;
}

.carousel .slide {
  text-align: center;
  min-width: 100%;
  margin: 0;
  position: relative;
}

.carousel .slide img {
  vertical-align: top;
  border: 0;
  width: 100%;
}

.carousel .slide iframe {
  border: 0;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  display: inline-block;
}

.carousel .slide .legend {
  -o-transition: all .5s ease-in-out;
  color: #fff;
  text-align: center;
  opacity: .25;
  -o-transition: opacity .35s ease-in-out;
  background: #000;
  border-radius: 10px;
  width: 90%;
  margin-left: -45%;
  padding: 10px;
  font-size: 12px;
  transition: opacity .35s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
}

.carousel .control-dots {
  text-align: center;
  z-index: 1;
  width: 100%;
  margin: 10px 0;
  padding: 0;
  position: absolute;
  bottom: 0;
}

@media (width >= 960px) {
  .carousel .control-dots {
    bottom: 0;
  }
}

.carousel .control-dots .dot {
  -o-transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity= 30);
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 8px;
  transition: opacity .25s ease-in;
  display: inline-block;
  box-shadow: 1px 1px 2px #000000e6;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity= 100);
}

.carousel .carousel-status {
  text-shadow: 1px 1px 1px #000000e6;
  color: #fff;
  padding: 5px;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.carousel:hover .slide .legend {
  opacity: 1;
}

.carousel .control-dots {
  z-index: 50;
  pointer-events: auto;
  position: absolute;
  bottom: -45px;
  left: 50%;
  transform: translateX(-50%);
}

.carousel .control-dots .dot {
  cursor: pointer;
  border-radius: 0;
  width: 12px;
  height: 12px;
  margin: 0 8px;
}

.carousel.carousel-slider {
  overflow: visible !important;
}

.custom-carousel .control-dots {
  z-index: 50;
  pointer-events: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3px !important;
}
/*# sourceMappingURL=HomePage.cf4424d6.css.map */
