.carousel .control-dots {
    position: absolute;
    bottom: -45px; 
    left: 50%;
    transform: translateX(-50%);
    z-index: 50; 
    pointer-events: auto;

}
.carousel .control-dots .dot {
    margin: 0 8px;
    cursor: pointer;
    width: 12px;
    height: 12px;
    border-radius: 0;
}

.carousel.carousel-slider {
    overflow: visible !important; 
}

.custom-carousel  .control-dots {
    position: absolute;
    bottom: 3px !important; 
    left: 50%;
    transform: translateX(-50%);
    z-index: 50; 
    pointer-events: auto;

}